import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Collaborating With Other Schools`}</h1>
    <p>{`It's no doubt that other schools do things differently than each other. For example, Irvine High does its elections using a popular vote, versus Northwood which has multiple different voting periods and types of voting, including an "election convention." Woodbridge does a "clash of the classes" and Irvine does a Unity week. Every school has different traditions and activities. What if every school met to share ideas and do more district wide events?`}</p>
    <p>{`Throughout the year, my Junior Class Council has planned "district-wide" events. This means that events like the Start of School Kickoff Bonding were technically available for anyone in IUSD to join, but only IHS students took advantage of it... because it was advertised only to them. I propose that every school work together more often and do similar things. This wouldn't entail every school following only one set of event schedule, but rather sharing ideas and creating competitions between schools. `}</p>
    <p>{`Realistically, this could happen if ASB President and Vice-Presidents met on a consistent basis. At the moment, only the ASB advisors at each school meet on a recurring schedule, but it would be extra helpful if the students met as well. We could learn about things that Portola does at their school, and do it at Irvine, and vice versa! We would open up a communication channel between all the ASBs, meet monthly or twice a month and just talk about what each school is doing. For example, if University High is about to do a sock drive, we can see if there's a possibility to do a school by school competition. This, at the end of the day, will promote school spirit and friendly competition.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      